@import '../../scss/colors';
@import '../../scss/variable';


.footer {
  padding: 20px 100px;
  margin-top: 60px;
  @media screen and (max-width: 400px) {
    padding: 20px 0;
  }
  .content {
    width: 80%;
    margin: auto;
    .logo-title {
      font-size: 30px;
      font-weight: 800;
      .purple {
        color: $purple;
      }
    }
    .fa-facebook-official {
      color: $white;
      font-size: 20px;
    }
    .footer-desc {
      margin-top: 20px;
      margin-bottom: 10px;
      &.pl-40 {
        padding-left: 40px;
        @media screen and (max-width: 400px) {
          padding-left: 0px;
        }
      }
      .desc {
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.007em;
        color: $white;
      }
    }
  }
}

.copy-right {
  border-top: 0.2px solid #aba3a3;
  padding-top: 10px;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}