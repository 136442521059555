@import '../../scss/colors';
@import '../../scss/variable';

.navbar-container {
  .bg-light {
    background-color: $white !important;
  }
  .help-text {
    font-size: 16px;
    color: #737373;
  }
  .logo-icon-med {
    width: 125px;
    margin-left: 7px;
    @media screen and (max-width: 400px) {
      width: 70px;
    }
  }
  .navbar-toggler {
    &.collapsed {
      @media screen and (max-width: 400px) {
        position: relative;
        top: -40px;
      }
    }
  }
  .logo-icon {
    width: 25px;
    margin-right: 10px;
  }
  .logo-title {
    font-size: 30px;
    font-weight: 800;
    .purple {
      color: $purple;
    }
  }
  .medsurg-header {
    position: relative;
    top: -70px;
    background: $white;
    @media screen and (max-width: 400px) {
      position: relative;
      top: -36px;
    }
  }
  .nav-link {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
  }
  .navbar-nav-scroll {
    @media screen and (max-width: 400px) {
      min-height: 180px;
    }
  }
  .navbar-collapse {
    justify-content: right;
  }
  .search-input {
    .form-control {
      border-radius: 50px;
      border: 1px solid #dfdfdf;
    }
  }
}