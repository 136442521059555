@import url('../src/assets/fonts/fonts.scss');
@import '~bootstrap/scss/bootstrap';
@import './scss/colors';
@import './scss/variable';
@import './assets/icons/icomoon/style.css';

body {
  font-family: 'Montserrat';
  font-weight: 500;
  letter-spacing: 0;
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .button-box {
    width: 450px;
    margin: auto;
    @media screen and (max-width: 1400px) {
      width: 380px;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background: $border-grey;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: $border-grey;
  }

  .p-100 {
    padding: 10px 50px 0 90px;
    @media screen and (max-width: 400px) {
      padding: 0 15px;
    }
    @media screen and (min-width: 401px) and (max-width: 821px) {
      padding: 0 25px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #afafaf;
    margin-bottom: 10px;
  }
}
