@import '../../scss/colors';
.loader {
  .spinner-border {
    height: 2rem;
    left: 48%;
    position: fixed;
    top: 50%;
    width: 2rem;
    z-index: 110;
  }
  .overlay {
    background: $overlay-background;
    opacity: 0.7;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }
}
