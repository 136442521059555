$black: #000000;
$white: #ffffff;
$purple: #6c74f4;
$grey-input: #f7f7f7;

$charcoal: #333333;
$drag-icon: #7c7c7a;
$add-category: #c7c7c7;
$placeholder-color: #acacac;
$light-black-color: #080808;
$button-color: #212529;
$red-color: #e35e56;
$bright-red: #ff0000;
$green-color: #63bf87;
$border-grey: grey;
$grey-color: #e6e6e6;
$light-grey: #efefef;
$grey-text: #acacac;
$subtitle-text: $drag-icon;
$circled-icon: #dddbdb;
$overlay-background: #0000005c;
$dropdown-bg: #f2f2f2;
$border-color: #707070;
$details: #999999;
$dropdown-border: #b9b9b9;
$dropdown-list-box: #00000029;
$table-row: #f4f4f4;
$input-bg: #e4e4e4;
$green-border: #12cc0a;
$incomplete-color: #f7cecc;
