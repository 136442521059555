@import '../../scss/colors';

.toast-container {
  z-index: 2000 !important;
  .toast {
    color: $white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 400px;
    font-size: 18px;
    padding: 10px 0;
    cursor: pointer;
  }
}
